import $ from 'jquery';
import 'what-input';
import retina from 'retinajs';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();
window.addEventListener('load', retina);
$('.top-bar').on('sticky.zf.stuckto:top', function() {
  $(this).addClass('shrink');
}).on('sticky.zf.unstuckfrom:top', function() {
  $(this).removeClass('shrink');
})


$(document).ready(function(){
  $(window).on("scroll", function() {
    var scrollPos = $(window).scrollTop();
    var hero = $('.hero');
    var heroTop = hero.offset().top;
    scrollPos > 0 ? $("body").addClass("sticky-active") : $("body").removeClass("sticky-active");
    // $('.hero').css('background-position-y',($scrolled*0.35)+'px');
    var limit = heroTop + hero.innerHeight();

    var newCentre = (2 * scrollPos);

    // if (scrollPos > heroTop && scrollPos <= limit) {
    //   hero.css({'background-position-y' : newCentre + 'px'})
    // } else {
    //   console.log("Not changing");
    //   hero.css({'background-position-y' : 'center'})
    // }
  });

  runParallaxLoop();

});

const parallaxables = document.querySelectorAll('.hero');

function runParallaxLoop() {
    requestAnimationFrame(runParallaxLoop);
    parallax();
}

function parallax() {
    parallaxables.forEach(parallaxable => {
        // let distance = window.scrollY * 0.5;
        let yPos = (parallaxable.getBoundingClientRect().top - window.pageYOffset) * 0.05 + 50;
        if (yPos < 0) {
          yPos = 0;
        }
        parallaxable.style.backgroundPositionY = yPos + '%';
        // parallaxable.style.transform = `translate3d(0,-${distance}px, 0)`;
    });
}
